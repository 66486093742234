export function Logo() {
  return (
    <div className="flex items-end text-brand">
      <svg viewBox="0 0 156 56" fill="none" className="h-8">
        <g clipPath="url(#clip0_1_6150)">
          <path
            d="M54.7487 19.0478H63.6226L57.7449 54.7209H48.8362L49.7851 48.8779C46.5352 53.5877 42.2286 55.9235 36.7888 55.9235C33.4624 55.9235 31.0188 54.7174 29.4477 52.2704C27.8766 49.8616 27.4387 46.5386 28.1338 42.3015L32.006 19.0444H40.9112L37.3727 40.0005C36.6046 44.9675 38.1027 47.922 41.8601 47.922C44.0916 47.922 46.1076 47.1052 47.9012 45.4576C49.8686 43.6571 51.0851 41.1788 51.5196 38.551L54.7487 19.0409V19.0478Z"
            fill="#003594"
          ></path>
          <path
            d="M91.6835 19.3398L90.1888 26.9694C89.3859 26.8234 88.5447 26.7504 87.7036 26.7504C83.3135 26.7504 80.3208 29.0654 78.0823 34.0637C77.1647 36.1145 76.5877 38.3008 76.2123 40.5184L73.7965 54.8669H64.9226L70.9116 19.1938H79.4935L78.4334 27.0424C80.9916 21.3836 84.4606 19.0479 88.8437 19.0479C90.0463 19.0479 90.9952 19.1591 91.6869 19.3398H91.6835Z"
            fill="#003594"
          ></path>
          <path
            d="M125.167 21.5296C126.738 23.9037 127.214 27.1884 126.519 31.3142L122.681 54.8669H113.738L117.245 33.7995C118.156 28.6135 116.512 26.0205 112.389 26.0205C109.88 26.0205 107.683 27.0111 105.792 28.9785C104.12 30.7199 103.13 33.007 102.723 35.3845L99.4243 54.7904H90.5504L96.5393 19.1174H105.413L104.318 25.5443C107.603 20.5077 112.021 17.9877 117.537 17.9877C121.006 17.9877 123.564 19.1556 125.167 21.5296Z"
            fill="#003594"
          ></path>
          <path
            d="M154.868 44.0221C154.868 47.71 153.481 50.6263 150.669 52.7848C147.857 54.9364 144.277 56 139.898 56C137.791 56 135.848 55.8088 134.068 55.4265C132.157 55.0163 130.436 54.3837 128.91 53.5217L130.405 45.8886C131.854 47.06 133.467 47.9081 135.243 48.433C136.728 48.8814 138.33 49.1004 140.044 49.1004H140.106C140.217 49.1004 140.329 49.1004 140.44 49.0934C140.509 49.0934 140.579 49.0934 140.648 49.0865C140.76 49.0865 140.871 49.076 140.979 49.0656C141.931 48.9961 142.786 48.8188 143.523 48.5373C143.523 48.5373 143.533 48.5373 143.537 48.5373C146.922 47.3694 149.095 44.2445 150.513 41.1093C149.359 42.6943 147.993 44.2515 146.37 45.3012C146.387 45.1587 146.394 45.0127 146.394 44.8633C146.394 43.0767 144.972 41.7593 143.839 41.2483C143.255 40.9946 142.345 40.6644 141.104 40.2264C139.845 39.8232 138.716 39.3609 137.708 38.8326C133.69 36.7436 131.681 33.6605 131.681 29.6041C131.681 26.1352 132.921 23.3232 135.368 21.1716C136.495 20.1949 137.819 19.4511 139.355 18.9227C141.166 18.304 143.273 17.9912 145.664 17.9912C147.843 17.9912 149.943 18.2414 151.966 18.7315C153.162 19.0235 154.326 19.3989 155.47 19.8577L154.02 27.2614C151.538 25.6242 148.66 24.7865 145.376 24.8213C145.25 24.8213 145.129 24.8213 145.011 24.8213C144.722 24.8213 144.441 24.8422 144.162 24.8734C143.94 24.8943 143.725 24.9256 143.512 24.9638C143.446 24.9742 143.38 24.9881 143.314 24.9986C143.224 25.0159 143.134 25.0333 143.047 25.0577C139.195 25.9336 136.599 29.1071 134.965 32.8124C136.31 30.6782 138.045 28.7108 140.165 27.609C140.089 27.9183 140.047 28.2555 140.044 28.617C140.044 29.9274 140.728 31.0327 142.101 31.9503C142.835 32.4404 143.763 32.8749 144.896 33.2538C148.146 34.3174 150.589 35.5931 152.306 37.1642C154.02 38.7353 154.865 41.0363 154.865 44.029L154.868 44.0221Z"
            fill="#003594"
          ></path>
          <path
            d="M48.4956 0L48.1445 2.07163C47.5223 5.74564 44.3419 8.43598 40.6157 8.43598H24.3347L16.6982 54.7939H7.3515L15.061 8.43598H0L0.434486 5.72478C0.96282 2.42617 3.80957 0 7.1499 0H48.499H48.4956Z"
            fill="#003594"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_1_6150">
            <rect width="156" height="56" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
      <div className="flex h-full flex-col justify-end">
        <span className="text-lg font-black italic leading-[14px]">DASH</span>
      </div>
    </div>
  );
}
